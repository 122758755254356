<template>
  <v-responsive min-width="1302">
          <v-progress-circular class="loader" size='70' color="black" v-if="loader" indeterminate ></v-progress-circular>
    <v-app class="bgr_clr_f9f9f9">
      <div class="bgr_clr_f9f9f9">
        <div class="padd-t-8 padd-b-8 padd-l-24">
          <!-- <span class="fsize12 clr-1867c0"
            >Customer / New Customer Creation</span
          > -->
          <v-breadcrumbs :items="items">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                class="fsize12 clr-1867c0"
                @click="$router.push('/loan')"
                :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
        <div class="padd-t-16 padd-b-0 padd-l-24">
          <span class="fsize20 clr-000 fw-bold">Loan Detail</span>
        </div>
        <div class="margin_bottom-7rem">
          <div class="marg-t-16 padd-l-24 padd-r-24">
            <v-card class="wholeCard padd-t-24 padd-0-40 min-height_1308">
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    Loan Details
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="row marg-0 padd-t-24">
                    <div class="w-464 h-72 h-68">
                      <div class="custom-label fsize14 clr_55585a">
                        Customer Name
                      </div>

                      <v-autocomplete
                        class="w-464 h-40 border_radius padd-8-16 fsize14"
                        v-model="customerName"
                        :search-input.sync="searchInput"
                        :items="customerscrips"
                        @select="customerSelect = true"
                        hide-no-data
                        hide-selected
                        outlined
                       :disabled="!Loandetails"
                       append-icon=""
                          disabled: 
                        dense
                        clearable
                      
                        placeholder="Search your name"
                        :class="{
                          'is-invalid':
                            submittedLoan && this.customerName == '',
                        }"
                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                      ></v-autocomplete>
                      <div
                        class="fsize12 text-danger marg-t-4"
                        v-if="this.submittedLoan && this.customerName == ''"
                      >
                        Please Enter Customer Name
                      </div>
                    </div>
                   
                  
                 
                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize14 clr_55585a">
                          Loan Category
                        </div>

                        <v-select
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            fsize14
                            outLine_none
                          "
                          :items="loanCategoryItem"
                          @change="ChangeloanCategory()"
                          label=""
                          dense
                          outlined
                          v-model="loanCategory"
                          :disabled="!Loandetails"
                          :class="{
                            'is-invalid h-40':
                              submittedLoan && this.loanCategory == '',
                          }"
                        ></v-select>
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submittedLoan && this.loanCategory == ''"
                        >
                          Please Choose Loan Category
                        </div>
                      </div>
                    </div>
  
                     
                    <v-row class="marg-0 marg-t-10" v-if="VehicleCategory">
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72">
                          <div class="custom-label fsize14 clr_55585a">
                            Vehicle Condition
                          </div>

                          <v-select
                            class="fsize14 marg_top w-225"
                            v-model="Vehicletype"
                            :items="VehicleConditiontype"
                            label=""
                            dense
                            @change="VehicleType()"
                            outlined
                             :disabled="!Loandetails"
                            :class="{
                              'is-invalid h-40':
                                submittedLoan && this.Vehicletype == '',
                            }"
                          ></v-select>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedLoan && this.Vehicletype == ''"
                          >
                            Please Choose Vehicle Condition
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72 marg-l-12 w-225">
                          <div class="custom-label fsize14 clr_55585a">
                            Vehicle Type
                          </div>

                          <v-select
                            class="fsize14 marg_top w-225"
                              hide-no-data
                        hide-selected
                            v-model="vehicleType"
                           :disabled="!Loandetails"
                            :items="Vehiclemaketype"
                            label=""
                            dense
                            outlined
                            :class="{
                              'is-invalid h-40':
                                submittedLoan && this.vehicleType == '',
                            }"
                          ></v-select>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedLoan && this.vehicleType == ''"
                          >
                            Please Choose Vehicle Type
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="marg-0 marg-t-10" v-if="VehicleCategory">
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72">
                          <div class="custom-label fsize14 clr_55585a">
                            Vehicle Make
                          </div>

                          <v-select
                            class="fsize14 marg_top w-225"
                              hide-no-data
                        hide-selected
                            :items="
                           vehicleType == 'TWO WHEELER'
                                ? Vehicletwowheel
                                : Vehiclefourwheel
                            "
                            v-model="vehicleMake"
                            label=""
                            dense
                            outlined
                             :disabled="!Loandetails"
                            :class="{
                              'is-invalid h-40':
                                submittedLoan && this.vehicleMake == '',
                            }"
                          ></v-select>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedLoan && this.vehicleMake == ''"
                          >
                            Please Choose Vehicle Make
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        xs="6"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72 marg-l-12 w-225">
                          <div class="custom-label fsize14 clr_55585a">
                            Vehicle Color
                          </div>

                          <v-select
                            class="fsize14 marg_top w-225"
                            :items="Vehiclecolor"
                            label=""
                            v-model="vehicleColor"
                            :disabled="!Loandetails"
                            dense
                            outlined
                            :class="{
                              'is-invalid h-40':
                                submittedLoan && this.vehicleColor == '',
                            }"
                          ></v-select>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedLoan && this.vehicleColor == ''"
                          >
                            Please Choose Vehicle Color
                          </div>
                        </div>
               
                      </v-col>
                      <v-col>
                             
                  </v-col>
                    </v-row>
                  </div>
                 
                </v-col>
               

            
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0 mt-4">
                    <div v-if="!Loandetails"  class="fsize14 clr-1867c0 cursorpointer padd-t-24 fright " @click="Loandetails = true">
                    Edit
                  </div>
                    <div v-if="Loandetails" class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click='updateLoanDetails'>
                      Cancel
                    </div>
                </v-col>
              
              </v-row>
              <div>
                <!-- <hr class="lineBelowHead marg-t-32" /> -->

                <!-- --------------------second Line----------------------------- -->
                <v-row class="marg-0" v-if="Showvehicletype">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <!-- <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Old Vehicle Proofs
                    </div> -->
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="padd-t-24 row marg-0">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          Vehicle Owner Name
                        </div>
                        <input
                          type="text"
                          name=""
                          :disabled="!Loandetails"
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          v-model="vehicleOwnerName"
                          placeholder="Enter your Vehicle Owner Name"
                          autocomplete="off"
                          :class="{
                            'is-invalid':
                              submittedLoan && this.vehicleOwnerName == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="
                            this.submittedLoan && this.vehicleOwnerName == ''
                          "
                        >
                          Please Enter Vehicle Owner Name
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72 upload">
                          <div class="custom-label fsize13 clr_55585a">
                            RC Upload
                          </div>
                          <v-file-input
                            class="border-input h-40 border_radius w-464 upload"
                            show-size
                            v-model="rcUpload"
                            :disabled="!Loandetails"
                            :class="{
                              'is-invalid':
                                submittedLoan && this.rcUpload == null,
                            }"
                          ></v-file-input>

                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedLoan && this.rcUpload == null">
                            Please Enter RC Upload
                          </div>

                        </div>
                      </div>
                      <div class="marg-t-16">
                        <div class="w-464 h-72 upload">
                          <div class="custom-label fsize13 clr_55585a">
                            Insurance Upload
                          </div>
                          <v-file-input
                            class="border-input h-40 border_radius w-464 upload"
                            show-size
                             :disabled="!Loandetails"
                            v-model="insuranceUpload"
                            :class="{
                              'is-invalid':
                                submittedLoan && this.insuranceUpload == null,
                            }"
                          ></v-file-input>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedLoan && this.insuranceUpload == null
                            "
                          >
                            Please Enter Insurance Upload
                          </div>
                        </div>
                      </div>

                      <!-- <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Year of Purchase
                          </div>

                          <v-select
                            class="fsize14 marg_top w-464"
                            :items="educationalItem"
                            label=""
                            dense
                            outlined
                          ></v-select>
                        </div>
                      </div> -->

                      <v-row class="marg-0 marg-t-10">
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          xs="6"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72">
                            <div class="custom-label fsize14 clr_55585a">
                              Vehicle Number
                            </div>

                            <input
                              type="text"
                              name=""
                               :disabled="!Loandetails"
                              class="
                                w-225
                                h-40
                                border_radius
                                padd-8-16
                                border-input
                                fsize14
                                outLine_none
                              "
                              v-model="vehicleNumber"
                              placeholder="Enter your Vehicle Number"
                              autocomplete="off"
                              :class="{
                                'is-invalid':
                                  submittedLoan && this.vehicleNumber == '',
                              }"
                            />
                            <div
                              class="fsize12 text-danger marg-t-4"
                              v-if="this.submittedLoan && this.vehicleNumber == ''"
                            >
                              Please Enter Vehicle Number
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          xs="6"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72 marg-l-12 w-225">
                            <div class="custom-label fsize14 clr_55585a">
                              Year of Purchase
                            </div>

                            <v-select
                              class="fsize14 marg_top w-225"
                              :items="educationalItem1"
                              v-model="yearOfPurchase"
                              label=""
                              dense
                               :disabled="!Loandetails"
                              outlined
                              :class="{
                                'is-invalid h-40':
                                  submittedLoan && this.yearOfPurchase == '',
                              }"
                            ></v-select>
                            <div
                              class="fsize12 text-danger marg-t-4"
                              v-if="this.submittedLoan && this.yearOfPurchase == ''"
                            >
                              Please Choose Year of Purchase
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      
                    </div>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                   
                  </v-col>
                 
                </v-row>
              </div>

              <div>
                <!-- ----------------------Third Line---------------------------------------------->
                <v-row class="marg-0">
                
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
</v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="padd-t-0 row marg-0">
                      <!-- </div> -->

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Loan Amount
                          </div>

                          <v-text-field
                             label="" outlined dense
                            class=" w-464 h-40 padd-8-16 fsize14 pa"
                            v-model="loanAmount"
                           :disabled="!Loandetails"
                            placeholder="Enter your Loan Amount"
                            autocomplete="off"
                            prefix="INR"
                            :class="{
                              'is-invalid':
                                submittedLoan && this.loanAmount == '',
                            }"
                          ></v-text-field>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedLoan && this.loanAmount == ''"
                          >
                            Please Enter Loan Amount
                          </div>
                        </div>
                      </div>

                      <v-row class="marg-0 marg-t-10">
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          xs="6"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72">
                            <div class="custom-label fsize14 clr_55585a">
                              Tenure
                            </div>

                         <v-select
                              class="fsize14 marg_top w-225"
                              v-model="tenurevalue"
                              :items="Vehicletenure"
                              label=""
                              dense
                              outlined
                              :disabled="!Loandetails"
                              :class="{
                                'is-invalid h-40':
                                  submittedLoan && this.tenurevalue == '',
                              }"
                            ></v-select>
                            <div
                              class="fsize12 text-danger marg-t-4"
                              v-if="this.submittedLoan && this.tenurevalue == ''"
                            >
                              Please Choose Tenure
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          xs="6"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72 marg-l-12 w-225">
                            <div class="custom-label fsize14 clr_55585a">
                              Rate of Interest
                            </div>

                            <v-select
                              class="fsize14 marg_top w-225"
                              v-model="roi"
                              :items="Vehicleroi"
                              label=""
                              dense
                              outlined
                              :disabled="!Loandetails"
                              :class="{
                                'is-invalid h-40':
                                  submittedLoan && this.roi == '',
                              }"
                            ></v-select>
                            <div
                              class="fsize12 text-danger marg-t-4"
                              v-if="this.submittedLoan && this.roi == ''"
                            >
                              Please Choose Tenure
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <div class="mt-3">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Cash Received from Customer
                          </div>

                            <v-text-field
                            label="" outlined dense
                            class=" w-464 h-40 padd-8-16 fsize14"
                            v-model="cashReceived"
                            :disabled="!Loandetails"
                            placeholder="Enter your Cash Received from Customer"
                            autocomplete="off"
                            prefix="INR"
                            :class="{
                              'is-invalid':
                                submittedLoan && this.cashReceived == '',
                            }"
                           ></v-text-field>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedLoan && this.cashReceived == ''"
                          >
                            Please Enter Cash Received from Customer
                          </div>
                        </div>
                      </div>

                      <v-row class="marg-0 marg-t-10">
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                          xs="5"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72">
                            <div class="custom-label fsize14 clr_55585a">
                              Loan Start Date
                            </div>

                            <div
                              class="
                                h-40
                                w-255
                                padd-2-0
                                border_radius border-input
                                fsize14
                                outLine_none
                              "
                               :disabled="!Loandetails"
                            >
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                 :disabled="!Loandetails"
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="computedDateFormatted"
                                    persistent-hint
                                     :disabled="!Loandetails"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="date"
                                  :show-current="true" 

                                  no-title
                                  @input="menu = false"
                                ></v-date-picker>
                              </v-menu>

                              <div
                                class="fsize12 text-danger marg-t-4"
                                v-if="this.submittedLoan && this.date == ''"
                              >
                                Please Choose Loan Start Date
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                          xs="5"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72 marg-l-12 w-225">
                            <div class="custom-label fsize14 clr_55585a">
                              First Due Date
                            </div>
                            <div
                              class="
                                h-40
                                w-255
                                padd-2-0
                                border_radius border-input
                                fsize14
                                outLine_none
                              "
                             :disabled="!Loandetails"
                            >
                              <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                :disabled="!Loandetails"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="computedDateFormattedOne"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    :disabled="!Loandetails"
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="dateTwo"
                                  :disabled="!Loandetails"
                                  no-title
                                  @input="menu2 = false"
                                ></v-date-picker>
                              </v-menu>

                              <div
                                class="fsize12 text-danger marg-t-4"
                                v-if="this.submittedLoan && this.dateTwo == ''"
                              >
                                Please Choose First Due Date
                              </div>
                            </div>
                          </div>
                        </v-col>
                            <v-col
                        cols="2"
                        sm="2"
                        md="2"
                        xs="2"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div v-if="Loandetails">
                          <div class="marg-l-24 marg-t-20">
                            <v-btn
                              class="
                                btn_width_96
                                h-40
                                brl_clr_btn
                                fsize14
                                textTransform_None
                                bgr_clr_btn_save
                              "
                              depressed
                              plain
                              retain-focus-on-click
                              ripple
                              @click="updateloanDetails()"
                            >
                              Next
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                      </v-row>
<!-- 
                      <div v-if="this.loanAmountDetailNextBtn == true">
                        <div class="marg-t-32">
                          <v-btn
                            class="
                              btn_width_96
                              h-40
                              brl_clr_btn
                              fsize14
                              textTransform_None
                              bgr_clr_btn_save
                            "
                            depressed
                            plain
                            retain-focus-on-click
                            ripple
                            @click="saveloanDetails()"
                          >
                            Next
                          </v-btn>
                        </div>
                      </div> -->

                          
                    </div>

                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0 mt-4">
           

                  </v-col>
                </v-row>
              </div>

              <div>
                <hr class="lineBelowHead marg-t-32" />
                <v-row class="marg-0">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0 mt-3">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Deduction Charges
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="padd-t-24 row marg-0">
                      <!-- </div> -->

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Transfer Charges
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            :disabled="!Deductiondetail"
                            v-model="nameTransfer"
                            placeholder="Enter your Name Transfer"
                            autocomplete="off"
                            :class="{
                              'is-invalid':
                                submittedDeduction && this.nameTransfer == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedDeduction && this.nameTransfer == ''"
                              >
                            Please Enter Name Transfer
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Insurance Renewal
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="insuranceRenewal"
                            :disabled="!Deductiondetail"
                            placeholder="Enter your Insurance Renewal"
                            autocomplete="off"
                            :class="{
                              'is-invalid':
                                submittedDeduction &&
                                this.insuranceRenewal == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedDeduction &&
                              this.insuranceRenewal == ''"
                          >
                            Please Enter Insurance Renewal
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Other Charges
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="otherCharges"
                            :disabled="!Deductiondetail"
                            placeholder="Enter your Others"
                            autocomplete="off"
                            :class="{
                              'is-invalid':
                                submittedDeduction && this.otherCharges == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedDeduction && this.otherCharges == ''"
                          >
                            Please Enter Others
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Document Charges
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="documentCharges"
                            :disabled="!Deductiondetail"
                            placeholder="Enter your  Document Charges"
                            autocomplete="off"
                            :class="{
                              'is-invalid':
                                submittedDeduction &&
                                this.documentCharges == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedDeduction &&
                              this.documentCharges == ''
                            "
                          >
                            Please Enter Document Charges
                          </div>
                        </div>
                      </div>

                      <div v-if="Deductiondetail">
                        <div class="marg-t-34 marg-l-27">
                          <v-btn
                            class="
                              btn_width_96
                              h-40
                              brl_clr_btn
                              fsize14
                              textTransform_None
                              bgr_clr_btn_save
                            "
                            depressed
                            plain
                            retain-focus-on-click
                            ripple
                            @click="updateDeductiondata()"
                          >
                            Next
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <!--                    
  
                <div class="">
                      <div class="fright">
                        <v-btn
                          class="
                            btn_width_96
                            h-40
                            brl_clr_btn
                            fsize14
                            textTransform_None
                            bgr_clr_btn_save
                          "
                          depressed
                          plain
                          retain-focus-on-click
                          ripple
                          @click="deductionChargesSave()"
                        >
                          Save
                        </v-btn>
                      </div>
                    </div> -->
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                        <div v-if="!Deductiondetail"  class="fsize14 clr-1867c0 cursorpointer padd-t-24 fright " @click="Deductiondetail = true">
                    Edit
                  </div>
                    <div v-if="Deductiondetail" class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click='updateDeductiondetail'>
                      Cancel
                    </div>
                  </v-col>
                  <!-- </div> -->
                  <hr class="lineBelowHead marg-t-32" />
                </v-row>
              </div>

              <div>
                <hr class="lineBelowHead marg-t-0" />

                <v-row class="marg-0">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0 mt-4">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Guarantor Details
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="padd-t-24 row marg-0">
                      <!-- </div> -->

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Guarantor Name
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                           :disabled="!Guarantordetail"
                            v-model="guaranteeName"
                            placeholder="Enter your Guarantor Name "
                            autocomplete="off"
                            :class="{
                              'is-invalid':
                                submittedGuarantee && this.guaranteeName == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedGuarantee &&
                              this.guaranteeName == ''
                            "
                          >
                            Please Enter Guarantor Name
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Guarantor Aadhar
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="guaranteeAadhar"
                          :disabled="!Guarantordetail"
                            placeholder="Enter your Guarantor Aadhar"
                            autocomplete="off"
                            maxlength="12"
                            :class="{
                              'is-invalid':
                                submittedGuarantee &&
                                this.guaranteeAadhar == '',
                            }"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedGuarantee &&
                              this.guaranteeAadhar == ''
                            "
                          >
                            Please Enter Guarantor Aadhar
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Guarantor Mob No
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="guaranteeMobNo"
                          :disabled="!Guarantordetail"
                            maxlength="10"
                            placeholder="Enter your Guarantor Mob No "
                            autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :class="{
                              'is-invalid':
                                submittedGuarantee && this.guaranteeMobNo == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedGuarantee &&
                              this.guaranteeMobNo == ''
                            "
                          >
                            Please Enter Guarantor Mob No
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Guarantor Photo Upload
                          </div>

                          <v-file-input
                            class="border-input h-40 border_radius w-464 upload"
                            v-model="guaranteePhotoUpload"
                             :disabled="!Guarantordetail"
                            :class="{
                              'is-invalid':
                                submittedGuarantee &&
                                this.guaranteePhotoUpload == null,
                            }"
                          ></v-file-input>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedGuarantee &&
                              this.guaranteePhotoUpload == null
                            "
                          >
                            Please Enter Guarantor Photo Upload
                          </div>
                        </div>
                      </div>

                      <div v-if="Guarantordetail">
                        <div class="marg-t-34 marg-l-27">
                          <v-btn
                            class="
                              btn_width_96
                              h-40
                              brl_clr_btn
                              fsize14
                              textTransform_None
                              bgr_clr_btn_save
                            "
                            depressed
                            plain
                            retain-focus-on-click
                            ripple
                            @click="updateGuaranteeDetails()"
                          >
                            Save
                          </v-btn>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="">
                      <div class="fright">
                        <v-btn
                          class="
                            btn_width_96
                            h-40
                            brl_clr_btn
                            fsize14
                            textTransform_None
                            bgr_clr_btn_save
                          "
                          depressed
                          plain
                          retain-focus-on-click
                          ripple
                          @click="guaranteeDetailsSave()"
                        >
                          Save
                        </v-btn>
                      </div>
                    </div> -->
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0 mt-3">
                      <div v-if="!Guarantordetail"  class="fsize14 clr-1867c0 cursorpointer padd-t-24 fright " @click="Guarantordetail = true">
                    Edit
                  </div>
                    <div v-if="Guarantordetail" class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click='updateGuarantorDetails'>
                      Cancel
                    </div>
                  </v-col>
                  <!-- </div> -->
                </v-row>
                <hr class="lineBelowHead marg-t-32" />
                <v-row class="marg-0">
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div class="fsize14 clr-000 fw-bold padd-t-36">
                      Link Guarantor AADHAR
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="pb-10 pt-10">
                      <v-btn
                        depressed
                        plain
                        retain-focus-on-click
                        ripple
                        class="btn-clr textTransform_None bgr_clr_btn_cancel"
                      >
                        Link Aadhar
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  </v-col>
                  <!-- </div> -->
                </v-row>
              </div>

              <!-- <div class="fsize14 clr-000"> Pettioner Detail</div> -->
            </v-card>
          </div>

          <div>
            <div class="fright">
              <div class="row marg-t-20 marg-r-24">
                <div class="w-96">
                  <v-btn
                    class="
                      btn_width_96
                      h-40
                      brl_clr_btn
                      fsize14
                      textTransform_None
                      bgr_clr_btn_cancel
                    "
                    depressed
                    plain
                    retain-focus-on-click
                    ripple
                  >
                    Cancel
                  </v-btn>
                </div>
                <div class="marg-l-16 w-96">
                  <v-btn
                    class="
                      btn_width_96
                      h-40
                      brl_clr_btn
                      fsize14
                      textTransform_None
                      bgr_clr_btn_save
                    "
                    @click="loanSave()"
                    depressed
                    plain
                    retain-focus-on-click
                    ripple
                  >
                    Save
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app>
  </v-responsive>
</template>

<script>
import httpService from "../js/httpservice";
import Vue from "vue";
export default {
  data() {
    return {
      // educationalItem: ["UG", "Graduate", "PG", "Professional", "others"],
      // educationalItem1: ["UG", "Graduate", "PG", "Professional", "others"],
      educationalItem1:[],
      marriedItem: ["Yes", "No"],
      loanCategoryItem: ["Vehicle Loan", "Cash"],
      vehicleCondtionItem: ["New", "Old"],
      vehicleTypeItem: ["Two Wheeler", "Four Wheeler"],
      married: "",
      VehicleCategory: false,
      Vehicletype: false,
      date: "",
      dateTwo: "",
      menu: false,
      modal: false,
      menu2: false,
      isDisabled: false,
      VehicleCondtion: false,
      items: [],
      customerName: "",
      searchInput: "",
      loanCategory: "",
      vehicleType: "",
      vehicleMake: "",
      vehicleColor: "",
      loanAmount: "",
      tenurevalue: "",
      roi: "",
      cashReceived: "",
      nameTransfer: "",
      insuranceRenewal: "",
      otherCharges: "",
      documentCharges: "",
      guaranteeName: "",
      guaranteeAadhar: "",
      guaranteeMobNo: "",
      guaranteePhotoUpload: null,
      vehicleOwnerName: "",
      rcUpload: null,
      insuranceUpload: null,
      vehicleNumber: "",
      yearOfPurchase: "",
      currentSearchUser:"",
      aadharnumber:"",
      mobilenumber:"",
      pannnumber:"",
      whatsappnumber:"",
      guarantorData:"",
      // computedDateFormatted: "",
      submitted: false,
      Deductiondetail: false,
      Guarantordetail: false,
      // --------------
      laonAmountDetails: false,
      Loandetails: false,
      deductionCharges: false,
      guaranteeDetail: false,
      submittedLoan: false,
      submittedDeduction: false,
      submittedGuarantee: false,
      vehicleDetailNext: false,
      loanAmountDetailNextBtn: true,
      deductionNextBtn: false,
      guaranteeNextBtn: false,
      disabled: 0,
      loandisabled: 0,
      deductionDisabled: 0,
      guaranteeDisabled: 0,
      Showvehicletype: false,
      vehicleLoan: [],
      VehicleConditiontype: [],
      Vehiclecolor: [],
      Vehiclefourwheel: [],
      Vehicletwowheel: [],
      Vehiclemaketype: [],
      customerNames: [],
      customerscrips: [],
      Vehicletenure: [],
      Vehicleroi:[],
      customerSelect:false,
      areaname:"",
      aadharid:"",
      mobileid:"",
      panid:"",
      whatsappid:"",
      Disabled: false,
      loader: false,
      items: [{text: "Loan", disabled: false, href: "loan",}, { text: "Loan Detail", disabled: true,href: "breadcrumbs_link_1",},],
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    // computedDateFormattedOne
    computedDateFormattedOne() {
      return this.formatDate(this.dateTwo);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },

    //search listen
    searchInput: function (val) {
      if(val) {
        if(val.length > 2){
        this.searchData(val);
        }
      } else {
        this.customerscrips = [];
        //this.currentSearchUser = []
      }
    },
  },

  methods: {
    
  /** /
     * Method to call save Guarantordata 
     * @author Saran Raj
     * @since 21/11/2021
     * @return na
     */

    updateGuaranteeDetails() {
         this.loader = true
          this.submittedGuarantee = true
   if(
     this.guaranteeName == "" || this.guaranteeAadhar == "" || this.guaranteeMobNo == "" || this.guaranteePhotoUpload ==  ""
   ){
     return false
   }
         const formData = new FormData();
         formData.append('guarantorDetails' , JSON.stringify({
           "user_id": this.userId,
           "guarantorId": this.guarantorData,
           "branch_id": "1",
           "loan_id": this.loanid,
           "guarantor_name": this.guaranteeName,
           "guarantor_mobile": this.guaranteeMobNo,
           "guarantor_aadhar": this.guaranteeAadhar,
      }))
        formData.append('guarantorPhoto',this.guaranteePhotoUpload)
         httpService.saveGuarantorData(formData).then((response) =>{
           if(response.status == 200){
             if(response.data['status'] == 1){
               console.log('Data Uploaded Successfully')
               localStorage.setItem('guaranteedetailupload', JSON.stringify(response.data.result))
                Vue.$toast.success("Guarantor details updated successfully", {
              position: "top-right",
              timeout: 2381,
             hideProgressBar: false,
            closeButton: false,
            icon: true,
              // optional options Object
            });
             }
             else{
               console.log('Upload Failed')
             }
           }
         }).finally(()=> {this.loader = false})
         this.laonAmountDetails = true;
         this.vehicleDetailsNext = false;
         this.loanAmountDetailNextBtn = true;
        //  this.guaranteeDisabled = 1
    },
  
     loanSave(){
       this.$router.push('/loan')
     },
    /** /
      
       * Method to call update customer details
       * @author Saranraj
       * @since 18/11/2021
       * @return na
       */

    ChangeloanCategory() {
      if (this.loanCategory == "Vehicle Loan") {
        this.VehicleCategory = true;
      } else if (this.loanCategory == "Cash") {
        this.VehicleCategory = false;
      }
    },

    /** /
     * Method to call change vehicletype details
     * @author Saran Raj
     * @since 18/11/2021
     * @return na
     */

    VehicleType() {
      if (this.Vehicletype == "NEW") {
        this.Showvehicletype = false;
      } else if (this.Vehicletype == "OLD") {
        this.Showvehicletype = true;
      }
      console.log(this.Showvehicletype, "this.Showvehicletype");
    },

    /** /
     * Method to call Vehicle Details
     * @author Saran Raj
     * @since 19/11/2021
     * @return na
     */
    getVehicleDetails() {
      httpService.getMasterData().then((response) => {
        console.log(response);
        if (response.status == 200) {
          if (response.data["status"] == 1) {
            this.vehicleLoan = response.data["result"];
            console.log(this.vehicleLoan);

            var vehicleColor = this.vehicleLoan["VEHICLE_COLOR"];
            var vehicleCondition = this.vehicleLoan["VEHICLE_CONDITION"];
            var vehiclemakeFourWheeler = this.vehicleLoan["VEHICLE_MAKE_FOUR_WHEELER"];
            var vehiclemakeTwoWheeler = this.vehicleLoan["VEHICLE_MAKE_TWO_WHEELER"];
            var vehiclemakeType = this.vehicleLoan["VEHICLE_TYPE"];
            var vehicleTenure = this.vehicleLoan['TENURE'];
            var vehiceRoi = this.vehicleLoan['ROI']

            for (let item of vehicleColor) {
              if (item.tagType == "VEHICLE_COLOR") {
                this.Vehiclecolor.push(item.tag);
              }
            }

            for (let item of vehicleCondition) {
              if (item.tagType == "VEHICLE_CONDITION") {
                this.VehicleConditiontype.push(item.tag);
              }
            }

            for (let item of vehiclemakeFourWheeler) {
              if (item.tagType == "VEHICLE_MAKE_FOUR_WHEELER") {
                this.Vehiclefourwheel.push(item.tag);
              }
            }

            for (let item of vehiclemakeTwoWheeler) {
              if (item.tagType == "VEHICLE_MAKE_TWO_WHEELER") {
                this.Vehicletwowheel.push(item.tag);
              }
            }

            for (let item of vehiclemakeType) {
              if (item.tagType == "VEHICLE_TYPE") {
                this.Vehiclemaketype.push(item.tag);
              }
            }
            
           for(let item of vehicleTenure){
             if(item.tagType == 'TENURE'){
               this.Vehicletenure.push(item.tag)
             }
           }

           for(let item of vehiceRoi){
             if(item.tagType == 'ROI'){
               this.Vehicleroi.push(item.tag)
             }
           }
          } 
          else {
            console.log("Response", "Failed to load Data");
          }
        }
      });
    },

      /** /
     * Method to call save Loan details
     * @author Saran Raj
     * @since 19/11/2021
     * @return na
     */

    updateloanDetails() {
  
    if(this.submittedLoan = true){
      if (
        this.customerName == "" ||
        this.loanCategory == "" ||
        this.vehicleCondtion == "" ||
        this.vehicleType == "" ||
        this.vehicleMake == "" ||
        this.vehicleColor == "" ||
        this.loanAmount == "" ||
        this.tenure == "" ||
        this.roi == "" ||
        this.cashReceived == "" ||
        this.date == "" ||
        this.dateTwo == "" 
       ) {
        
        return false;
      }
    }
       this.deductionDisabled = 1
      this.isDisabled = 1;
      this.loanAmountDetailNextBtn = false
      this.deductionNextBtn = true
  
      let jsondata = {
        user_id: this.userId,
        loan_id: this.loanid,
        loan_type: "VEHICLE",
        loan_amount: this.loanAmount,
        tenure: this.tenurevalue,
        roi: this.roi,
        cash_received: this.cashReceived,
        vehicle_condition: this.Vehicletype,
        vehicle_type: this.vehicleType,
        vehicle_make: this.vehicleMake,
        vehicle_color: this.vehicleColor,
        loan_start_date: this.date,
        first_due_date: this.dateTwo,
        branch_id: "1",
      };

      httpService.saveLoadData(jsondata).then((response) => {
      
        console.log(response);

        if (response.status == 200) {
          if (response.data["status"] == 1) {
              this.loader = true
            console.log("Response", "------------->Insert Successfully");
                localStorage.setItem('Loanid' ,JSON.stringify(response.data.result));
                 Vue.$toast.success("Loan details updated successfully", {
                 position: "top-right",
                 timeout: 2381,
             
              // optional options Object
            });
          } else {
            console.log("Failed Response");
          }
        }
      }).finally(()=> {this.loader = false})
    },

     /** /
     * Method to call savededuction details
     * @author Saran Raj
     * @since 21/11/2021
     * @return na
     */
    updateDeductiondata(){
    this.loader = true
   this.submittedDeduction = true
   if(
     this.nameTransfer == "" || this.insuranceRenewal == "" || this.otherCharges == "" || this.documentCharges ==  ""
   ){
     return false
   }
      this.guaranteeDetail = true;
      this.guaranteeNextBtn = true;
      this.deductionNextBtn = false;
      this.deductionDisabled = 0;
      this.guaranteeDisabled = 1;

    let jsonData = {

      user_id: this.userId,
      deduction_id: this.deductionData,
      branch_id: "1",
      loan_id: this.loanid,
      name_transfer: this.nameTransfer,
      insurance_renewal: this.insuranceRenewal,
      other_charge: this.otherCharges,
      doc_charge: this.documentCharges
    };
    httpService.saveDeductionCharges(jsonData).then((response) =>{
      console.log(response)
      if(response.status == 200){
        if(response.data['status'] == 1){
            console.log('Updated Successfully')
            localStorage.setItem('deductiondetails', JSON.stringify(response.data.result))
            this.$toast.success("Deduction details updated successfully", {
            position: "top-right",
            timeout: 1381,
            });
        }
        else{
          console.log('Response', 'Failed not save data')
        }
      }
    }).finally(()=> {this.loader = false})
    },

     /** /
     * Method to call searchdata
     * @author Saran Raj
     * @since 20/11/2021
     * @return na
     */

    searchData(value) {
      let json = {
        customer_name: value,
      };

      httpService.searchUsername(json).then((response) => {
        if (response.status == 200) {
          if (response.data["status"] == 1) {
            this.customerNames = response.data["result"]
            var customerresponse = response.data.result;
            this.currentSearchUser  = response.data['result'][0]['area_name']
            this.areaname == this.currentSearchUser
            this.aadharnumber = response.data['result'][0]['aadhar_no']
            this.aadharid == this.aadharnumber
            this.mobilenumber = response.data['result'][0]['mobile_number']
            this.mobileid == this.mobilenumber
            this.pannnumber = response.data['result'][0]['pan_card']
            this.panid == this.pannnumber
            this.whatsappnumber = response.data['result'][0]['whatsapp_no']
            this.whatsappid == this.whatsappnumber
            console.log(this.areaname,'currentSearchUser')
            this.customerscrips = [];
            for (let idx of customerresponse) {
              this.customerscrips.push(idx.customer_name);
            }
            console.log(this.customerNames);
            console.log(this.customerscrips);
          } else {
            console.log("Failed");
          }
        }
      });
    },

     updateLoanDetails: function() {
        this.customerName = ''; 
        this.loanCategory = ''; 
        this.Vehicletype = ''; 
        this.vehicleType = '';
        this.vehicleMake = '';
        this.vehicleColor = '';
        this.loanAmount = '';
        this.tenurevalue = '';
        this.roi = '';
        this.cashReceived= '';
        this.date = '';
        this.dateTwo = '';
        this.Loandetails = false
        this.submittedLoan = false
    },

     updateDeductiondetail: function(){
       this.nameTransfer = "";
       this.insuranceRenewal = "";
       this.otherCharges = "" ;
       this.documentCharges = "";
       this.submittedDeduction = false
       this.Deductiondetail = false
     },

      updateGuarantorDetails: function(){
        this.guaranteeName = false
        this.guaranteeAadhar = false
        this.guaranteeMobNo = false,
        this.guaranteePhotoUpload = false,
        this.Guarantordetail = false
        this.submittedGuarantee = false
        
      },


    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  mounted() {
       //console.log(" in Loan Details", this.$store.state.message);
   
    // this.customerName = this.$store.state.message.customer_name;
    // this.loanAmount = this.$store.state.message.loan_amount;
    // this.roi = this.$store.state.message.roi;
    // this.tenurevalue = this.$state.message.tenure
    // this.loanCategory = this.$state.message.loan_type
    // this.cashReceived = this.$state.message.cash_received
    
    if (localStorage.getItem("guarantordetailupload") != undefined || localStorage.getItem("guarantordetailupload") != null) {
      this.guarantorData = JSON.parse(localStorage.getItem("guarantordetailupload"));
    }

    if (
      localStorage.getItem("deductiondetails") != undefined || localStorage.getItem("deductiondetails") != null) {
      this.deductionData = JSON.parse(localStorage.getItem("deductiondetails"));
    }

    if(localStorage.getItem("Loanid") != undefined || localStorage.getItem("Loanid") != null){
      this.loanid = JSON.parse(localStorage.getItem("Loanid"));
    }

    if(localStorage.getItem("userprofile") != undefined || localStorage.getItem("userprofile") != null){
      var data = JSON.parse(localStorage.getItem("userprofile"))
      this.userId = data['user_id']
      console.log(this.userId)
    }
    this.getVehicleDetails();
    
  },
};
</script> 

<style>
.v-text-field {
  margin: 0px !important;
  padding: 0px !important;
}
.v-text-field input {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 11px 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}
.input__prepend-outer {
  margin-left: 8px !important;
  margin-top: 8px !important;
}
.v-application--is-ltr .v-input__prepend-outer {
  margin-right: 9px !important;
  margin-left: 8px !important;
  margin-top: 6px !important;
}
.v-input .v-label {
  line-height: 24px !important;
}
.upload.v-application .primary--text {
  caret-color: #fff !important;
  color: #fff !important;
}
.vdp-datepicker {
  outline: none !important;
  width: 250px !important;
}

.padd-t-14 {
  padding-top: 4px !important ;
}
.icon.v-input__prepend-outer {
  margin-top: 0px !important;
}
.min_h-300 {
  min-height: 300px !important;
}
.padd-2-0 {
  padding: 2px 0px !important;
}
.v-text-field__details {
  min-height: 0px !important;
}
.marg-b-20 {
  margin-bottom: 20px !important;
}
.marg-l-27 {
  margin-left: 27px !important;
}
.v-input--is-disabled:not(.v-input--is-readonly) {
 
  cursor: pointer;
  background: #e9ecef !important;
  height: 40px !important;
}
[disabled] {
  cursor: pointer;
  background: #e9ecef !important;
}
</style>